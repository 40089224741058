import { Fragment } from 'react';
import { FieldError, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faClock } from '@fortawesome/free-regular-svg-icons';

import { useFormApi } from '@lamesarv-sdk/hooks';
import { navigate } from '@lamesarv-sdk/tools';

import { IForm } from '../Form';
import { validationSchema } from './FormNewsletterBlog.schema';
import * as FormClasses from '../Form.classes';

import { IFormProps } from '../Form';
import { IFormInput } from '@lamesarv-sdk/types';

interface IFormNewsletter extends IForm {
  firstName: string;
  lastName: string;
  email: string;
}

export const FormNewsletterBlog = (props: IFormProps) => {
  const [loading, submitted, error, sendForm] = useFormApi();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormNewsletter>({
    resolver: yupResolver(validationSchema) as unknown as Resolver<IFormNewsletter>,
  });

  const onSubmit: SubmitHandler<IFormInput> = async (formInput) => {
    await sendForm(formInput);

    reset();

    if (props.redirect) {
      navigate(props.redirect);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('formId')} value="28" />
      <input type="hidden" {...register('formType')} value="email_newsletter" />
      <h4 className="font-heading mb-2 text-2xl text-sage-700 font-semibold leading-tight py-4">
        Sign up to our newsletter
      </h4>
      <p className="mb-6 text-sm leading-loose text-gray-500">
        Amet minim mollit non deserunt est dolor do amet sint. Velit officia consequat duis.
      </p>
      <Fragment>
        {loading && (
          <div
            className={
              'flex items-center w-full bg-gray-100 border-l-4 border-gray-500 text-gray-700 py-2 px-3 mb-4 shadow-lg'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faClock} className="w-8 mr-2" />
            <span>Sending...</span>
          </div>
        )}
        {submitted && !error && (
          <div
            className={
              'flex items-center w-full bg-green-100 border-l-4 border-green-500 text-green-700 py-2 px-3 mb-4 shadow-lg'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faCheckCircle} className="w-8 mr-2" />
            <span>Thank you for requesting more information.</span>
          </div>
        )}
        {submitted && error && (
          <div
            className={
              'flex items-center w-full bg-red-100 border-l-4 border-red-500 text-red-700 py-2 px-3 mb-4 shadow-lg'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className="w-8 mr-2" />
            <span>An error has occurred, please try again later or contact us using alternative methods.</span>
          </div>
        )}
      </Fragment>
      <div className="relative mb-6 flex flex-wrap">
        <input
          {...register('firstName')}
          className="relative mb-2 w-full rounded border py-4 pl-4 text-sm md:mb-0"
          placeholder="First Name*"
        />
        <span className="absolute top-0 left-0 ml-4 -mt-2 inline-block bg-white px-1 text-xs text-gray-500">
          First Name
        </span>
        {errors.firstName && (
          <div className={FormClasses.errorMessageClasses}>{(errors?.firstName as unknown as FieldError)?.message}</div>
        )}
      </div>
      <div className="relative mb-6 flex flex-wrap">
        <input
          {...register('lastName')}
          className="relative mb-2 w-full rounded border py-4 pl-4 text-sm md:mb-0"
          placeholder="Last Name"
        />
        <span className="absolute top-0 left-0 ml-4 -mt-2 inline-block bg-white px-1 text-xs text-gray-500">
          Last Name
        </span>
        {errors.lastName && (
          <div className={FormClasses.errorMessageClasses}>{(errors?.lastName as unknown as FieldError)?.message}</div>
        )}
      </div>
      <div className="relative mb-6 flex flex-wrap">
        <input
          {...register('email')}
          className="relative mb-2 w-full rounded border py-4 pl-4 text-sm md:mb-0"
          placeholder="e.g hello@lamesarv.com"
        />
        <span className="absolute top-0 left-0 ml-4 -mt-2 inline-block bg-white px-1 text-xs text-gray-500">
          Email Address
        </span>
        {errors.email && (
          <div className={FormClasses.errorMessageClasses}>{(errors?.email as unknown as FieldError)?.message}</div>
        )}
      </div>
      <button className="mb-4 w-full rounded py-4 text-sm font-bold leading-normal text-white uppercase transition duration-200 bg-gradient-to-b from-blue-500 to-blue-700 hover:from-blue-900 hover:to-blue-600 cursor-pointer ">
        Sign up
      </button>
    </form>
  );
};
